import React from "react"
import { Link } from "gatsby"

import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import MailImg from "../../common/assets/image/alpacked/mail.svg"
import Text from "../../components/Text"
import bannerImg from "../../common/assets/image/alpacked/404/404.png"
import BannerWrapper, {
  BannerContent,
  BannerImage,
  ButtonGroup,
  Overlay,
} from "./thanksBanner.style"

const ThanksBanner = ({
  title,
  subtitle,
  text,
  buttonText,
  thankYouImg,
  image,
}) => {
  return (
    <BannerWrapper thankYouImg={thankYouImg}>
      <Overlay thankYouImg={thankYouImg}>
        <Container width="1220px">
          <BannerContent thankYouImg={thankYouImg}>
            <Heading as="h1" content={title} />
            <Text content={subtitle} />
            <Text content={text} className="text" />
            <div className="author-container">
              <img src={image} alt="Dmitriy" className="author" />
              <p className="details">
                Dmitriy Konstantynov{" "}
                <a href="mailto: dmitriy@alpacked.io">
                  <span>
                    <img src={MailImg} alt="email icon" className="mail" />
                  </span>
                </a>
              </p>
            </div>
            <ButtonGroup thankYouImg={thankYouImg}>
              <Link to="/">
                <Button className="primary" title={buttonText} />
              </Link>
            </ButtonGroup>
          </BannerContent>
          {!thankYouImg && (
            <BannerImage>
              <Image src={bannerImg} alt="Banner" />
            </BannerImage>
          )}
        </Container>
      </Overlay>
    </BannerWrapper>
  )
}

export default ThanksBanner
