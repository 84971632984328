import React from "react"

import DmitriyImg from "../common/assets/image/alpacked/dmitriy.jpg"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import ThanksBanner from "../containers/ThanksBanner"
import { ContentWrapper } from "../containers/alpacked.style"

const ThankYouPage = props => {
  return (
    <>
      <SEO
        location={props.location}
        title="Alpacked DevOps Company"
        shortDesc="Thank You"
        description="Thank You "
      />
      <Layout>
        <ContentWrapper>
          <ThanksBanner
            title={
              props?.location?.state?.name
                ? `Thanks ${props.location.state.name} for reaching out!`
                : `Thanks for reaching out!`
            }
            image={DmitriyImg}
            subtitle="My name is Dmitriy and I will be your point of contact."
            text="During business day I reply as soon as possible and will reach out to you within few hours maximum. If you reached out to us during night time I will reach out as soon as I wake up, even before my morning coffee 🙂."
            buttonText="BACK TO WEBSITE"
            thankYouImg
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default ThankYouPage
